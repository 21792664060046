<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Nouveau Courtier </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'courtierList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="two column centered row">
            <div class="ten wide column">
                    <div class="ui form">
                        <div class="field" :class="{'error': $v.courtier.label.$error}">
                            <label>Nom de Courtier</label>
                            <input type="text" placeholder="Nom de Courtier" v-model.trim="$v.courtier.label.$model" v-uppercase>
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.courtier.label.$error">
                                <p><i class="info circle icon"></i> Nom de Courtier est Obligatoire. </p>
                            </div>
                        </div>

                        <div class="field">
                            <label>Téléphone </label>
                            <input type="tel" placeholder="Téléphone" v-model="courtier.tel">
                        </div>

                        <div class="field" :class="{'error': $v.selected_assurance.$error}">
                            <label>Assurance </label>
                            <!--<v-select multiple :options="assurance_list" v-model="$v.courtier.assurance.$model" />-->
                            <multiselect track-by="_id" label="label" v-model="$v.selected_assurance.$model"
                                         :multiple="true" :options="assurance_list" />
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.selected_assurance.$error">
                                <p><i class="info circle icon"></i> L'assurance est Obligatoire. </p>
                            </div>
                        </div>

                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "courtierCrud",
        props:{
            current_courtier: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              courtier: {
                  label: '',
                  tel: '',
                  assurance: []
              },
              assurance_list: [],
              selected_assurance: []
          }
        },
        validations: {
            courtier: {
                label: {required},
            },
            selected_assurance: {required}
        },
        methods:{
            save(){
                let selected_assurance = [];
                this.selected_assurance.forEach(function(a){
                    selected_assurance.push( a._id )
                });
                this.courtier.assurance = selected_assurance;
                log('save courtier:', this.courtier);

                this.$v.$touch();
                if (this.$v.$invalid)  return;

                let data = {
                    collection: this.$SHARED.collections.courtier,
                    query: this.courtier
                };
                if(this.isEdit){ // update
                    data.query = {
                        _id: this.courtier._id
                    };
                    data.update = {'$set': this.courtier};
                    data.remove_id = true
                }
                let url = this.isEdit ? this.$SHARED.services.updateIfNotExist
                    : this.$SHARED.services.insertIfNotExist;

                // this.$store[this.isEdit ? 'requestUpdate' : 'requestInsert'](data).then(() => {
                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'courtierList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.courtier[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "Le courtier " + this.$SHARED.messages["7"]
                    }

                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                let data = {
                    collection: this.$SHARED.collections.courtier,
                    query: {
                        _id: this.courtier._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'courtierList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.courtier.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            },
            getAssurances() {
                log('getAssurances');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {
                        page: 1,
                        limit: -1,
                        sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
        },
        mounted() {
            if(this.current_courtier.hasOwnProperty('_id')){
                this.isEdit = true;
                this.courtier = JSON.parse(JSON.stringify(this.current_courtier));
                this.selected_assurance = JSON.parse(JSON.stringify(this.courtier.assurance_list));
                delete this.courtier.assurance_list;
            }
            this.getAssurances();
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>